.dashboard {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 5%;
}

.dashboard-header {
  background-color: white;
}

.dashboard-logo {
  height: 8rem;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  background-color: white;
}

.table-heading th {
  font-size: 1rem;
  background-color: #2774b8;
  color: white;
}

.styled-table th,
.styled-table td {
  padding: 0.2rem;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

.styled-table thead tr:nth-child(2) th {
  color: #333;
  font-weight: bold;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #25a342;
}

.print {
  background-color: #2774b8;
  color: white;
  font-size: 18px;
  border: 1px solid #2774b8;
  padding: 1.5% 2.5%;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 5%;
}

.print:hover {
  background-color: white;
  color: #2774b8;
}
