.login-container {
  height: 100vh;
  color: #25a342;
  position: relative;
}

.login-logo-container {
  background-color: white;
  width: 100%;
}
.login-container img {
  height: 8rem;
}

.login {
  position: absolute;
  top: 30%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -20%);
  width: 90%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  font-size: 20px;
  border: 3px solid #25a342;
  padding: 2% 4%;
}

.login-grp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 5%;
}

.login-input {
  width: 95%;
  padding: 2%;
  outline: #25a342;
  border: 1px solid #25a342;
  margin-top: 2%;
}

.login-form button {
  background-color: #25a342;
  color: white;
  font-size: 18px;
  border: 1px solid #25a342;
  padding: 1.5% 2.5%;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 5%;
}

.login-form button:hover {
  background-color: white;
  color: #25a342;
}

.register {
  position: absolute;
  top: 70%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -30%);
  width: 90%;
}
