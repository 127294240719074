#hero {
  text-align: left;
  margin-top: 20%;
}

.hero {
  padding-left: 5%;
  padding-right: 5%;
}

.hero h1 {
  color: #25a342;
  font-size: 84px;
  line-height: 5rem;
  margin-top: 1%;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 0;
  margin-bottom: 0;
}

.hero h2 {
  color: #25a342;
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 0;
}

.hero-paragraph {
  margin-top: 0;
  padding-top: 0;
  font-size: 20px;
}

.hero-date-venue {
  font-size: 28px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  color: #2774b8;
}

.hero-btn {
  color: white;
  font-size: 24px;
  font-weight: 400;
  background-color: #2774b8;
  padding: 2% 4%;
  border: none;
  margin: 0 5%;
  text-decoration: none;
}

#about {
  margin: 5% 0 0 0;
  background-color: white;
  padding: 2% 5%;
}

@media screen and (min-width: 576px) {
  .hero h1 {
    letter-spacing: 1rem;
    font-size: 86px;
  }
}

@media screen and (min-width: 640px) {
  .hero h1 {
    letter-spacing: 1.5rem;
    line-height: 6rem;
  }

  .hero-date-venue {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 992px) {
  #hero {
    margin-top: 17%;
  }
}

@media screen and (min-width: 1200px) {
  #hero {
    margin-top: 14%;
  }
}

@media screen and (min-width: 1800px) {
  #hero {
    margin-top: 10%;
  }

  .hero-date-venue {
    margin-bottom: 5rem;
  }

  .hero-btn {
    font-size: 30px;
    font-weight: 400;
    padding: 1.5% 3%;
  }
}
