.header {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.desktop-header {
  display: none;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5%;
}

.hamburger {
  height: 30px;
  width: 40px;
  cursor: pointer;
  fill: #25a342;
}

.desktop-nav {
  display: none;
}

.logo {
  height: 4rem;
}

.mobile-nav {
  background-color: #272a31;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  width: 110vw;
  margin: 0 -5% 0 -5%;
  padding: 0;
  z-index: 100;
  position: relative;
}

.btn-close {
  height: 35px;
  width: 35px;
  cursor: pointer;
  fill: white;
  position: absolute;
  top: 2.5%;
  right: 10%;
}

.mobile-nav li {
  list-style: none;
  border-bottom: 1px solid #2774b8;
  padding-bottom: 10px;
  margin-top: 60px;
  width: 100%;
  align-self: center;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
}

.mobile-nav a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .mobile-nav {
    display: none;
  }

  .hamburger {
    display: none;
  }

  .desktop-header {
    display: flex;
    background-color: #2774b8;
    color: white;
    padding-right: 4%;
    padding-left: 4%;
    align-items: center;
    justify-content: space-between;
  }
  .desktop-header p {
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .desktop-header-link {
    width: 5rem;
    display: flex;
    justify-content: space-between;
  }

  nav {
    width: 60%;
    padding-right: 10%;
  }

  .desktop-nav {
    display: flex;
    justify-content: space-between;
  }

  .desktop-nav li {
    list-style: none;
    margin-right: 2%;
  }
  .desktop-nav a {
    text-decoration: none;
    color: #25a342;
    font-weight: 400;
    border-right: 1px solid #25a342;
    padding-right: 3%;
    padding-left: 3%;
  }
  .desktop-nav a:hover {
    border-bottom: 1px solid #25a342;
    padding-bottom: 2%;
  }

  .desktop-nav a:active {
    border-bottom: 1px solid #25a342;
    padding-bottom: 2%;
    font-weight: 500;
  }
}

@media screen and (min-width: 640px) {
  .desktop-header {
    padding-right: 5%;
    padding-left: 5%;
  }

  nav {
    padding-right: 5%;
  }
}

@media screen and (min-width: 768px) {
  header {
    padding-right: 2%;
  }

  .logo {
    height: 6rem;
    margin-left: 5%;
  }

  .desktop-nav a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 992px) {
  .desktop-nav a {
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media screen and (min-width: 1200px) {
  .desktop-nav a {
    padding-right: 7%;
    padding-left: 7%;
  }

  .logo {
    margin-left: 20%;
  }
}

@media screen and (min-width: 1800px) {
  .desktop-nav a {
    font-size: 1.8rem;
  }

   .logo {
    margin-left: 40%;
  }
}
