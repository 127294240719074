#category {
  background-color: #2774b8;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
}

#category h2 {
  color: white;
  text-align: left;
}

.special-category {
  margin-top: 15%;
}
.special-category h2 {
  margin-bottom: 0;
}
.special-category p {
  margin-top: 2%;
}

.category-card h2 {
  color: #000;
  text-align: left;
}

.category-card,
.category-card-2 {
  text-align: left;
  align-items: center;
  background: white;
  margin-bottom: 2%;
  padding: 5%;
  border-radius: 2%;
}

.category-card p {
  margin-top: 0;
  font-style: italic;
}

.category-paragraph {
  text-align: left;
  color: white;
}

.category-card img {
  width: 100%;
}

.category-card ul li {
  list-style-type: square;
  margin-bottom: 5%;
}

.category-card ul li {
  list-style-type: square;
  margin-bottom: 5%;
}

.card-item {
  font-weight: 500;
}

ul .bank-types li {
  list-style-type: lower-roman;
  font-weight: 500;
}

.category-card h3 {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .category-grp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .category-card {
    width: 45%;
    text-align: center;
    padding: 2%;
  }
  .category-card-2 {
    width: 45%;
    padding: 2%;
  }

  .category-card img {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background-color: #eee;
  }

  .special-category {
    margin-top: 5%;
  }

  .category-card img {
    width: 100%;
    height: 18rem;
  }

  .nomination-steps {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .step-card {
    width: 45%;
  }
}

@media screen and (min-width: 992px) {
  .category-card {
    width: 28%;
  }
}

@media screen and (min-width: 1200px) {
  .category-card-2 {
    width: 28%;
  }
}

@media screen and (min-width: 1800px) {
  .category-card img {
    width: 18rem;
    height: 18rem;
  }
}
