.process {
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
}

.process h2 {
  text-align: left;
}

.nomination-btn {
  color: white;
  font-size: 24px;
  font-weight: 400;
  background-color: #2774b8;
  padding: 2% 4%;
  text-decoration: none;
  border: none;
}

.nomination-steps {
  margin-bottom: 5%;
}

.step-card {
  border: 1px solid #2774b8;
  padding: 0 2%;
  margin-bottom: 3%;
}

.events {
  margin-top: 5%;
  border-top: 0.1rem solid #25a342;
}

.events h2 {
  margin-bottom: 2%;
}

.events h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.events p {
  margin-top: 0;
  padding-top: 0;
}

#contact {
  background-color: #2774b8;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 15%;
  padding-top: 2%;
  padding-bottom: 5%;
  margin-left: -6%;
  margin-right: -6%;
  color: white;
}

.contact-info {
  margin-bottom: 2%;
}
.contact-info span {
  margin-left: 2%;
}

.contact-cta {
  margin-top: 3%;
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon {
  fill: white;
}

.footer-quote {
  font-style: italic;
}

.copyright {
  text-align: center;
  border-top: 0.1rem solid #25a342;
  margin-left: -6%;
  margin-right: -6%;
  padding-top: 2%;
  margin-top: 0;
  margin-bottom: 5%;
  color: #25a342;
}

@media screen and (min-width: 576px) {
  .copyright {
    margin-top: 5%;
    margin-bottom: 0.2%;
  }

  #contact {
    margin-top: 8%;
  }
}

@media screen and (min-width: 786px) {
  .process p {
    font-size: 1.1rem;
  }

  #contact {
    padding-bottom: 3%;
  }

  .contact-icons {
    margin-top: 2%;
  }

  .copyright {
    margin-top: 2%;
  }

  .contact-icons {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .process p {
    font-size: 1.2rem;
  }
}
