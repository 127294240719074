.App {
  position: relative;
  /* text-align: center; */
  /* background-color: white; */
  color: #272a31;
}

/* .App.active {
  background-color: #272a31;
} */

